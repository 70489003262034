import React, { useState } from 'react';
import { navigate } from 'gatsby'
import { Box, Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import SidebarCSA from '../../components/SidebarCSA'

export default function CSAExplore() {
  const onClickButton_MyLearning = () => {
    navigate ('/cloud-security-architect')
  }
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'91vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarCSA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/62jMHpQfceQEi8lDvZdaQp/45304b0866602375ee27c84d304a86d4/my-learning-csa-explore.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '12px', left: '22%'}} kind='text' onClick={onClickButton_MyLearning} ><div className='beacon'></div>&nbsp; &nbsp;</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}